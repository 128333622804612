exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clinical-trials-js": () => import("./../../../src/pages/clinical-trials.js" /* webpackChunkName: "component---src-pages-clinical-trials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog-posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */)
}

